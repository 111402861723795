import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface DataItem {
  heading: string;
  icon: JSX.Element;
  value: string;
  description?: string;
  styles?: Record<string, string>
}

interface DashboardCardProps {
  dataItem: DataItem;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  dataItem,
}: DashboardCardProps) => {

  const { heading, icon, value, description } = dataItem;

  return (
    <Card sx={{ height: 150, borderRadius: 3, color: '#FFFFFF', ...dataItem.styles }}>
      <CardContent>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Stack
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            gap={"1rem"}
          >
            <Typography variant="h5">
              {heading}
            </Typography>
            {heading !== "Open Rate" ? (
              <Stack>
                <Typography variant="h6">
                  {value}
                </Typography>
                <Typography variant="caption">
                  {description}
                </Typography>
              </Stack>
            ) : (
              <Typography variant="h6" component="p">
                {value}
              </Typography>
            )}
          </Stack>
          {icon}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
