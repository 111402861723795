/** @jsxImportSource @emotion/react */
import { FC, ReactNode, useState } from 'react';
import { Box } from '@mui/material';

import { Navigation } from '../Navigation';
import { Header } from '../Header';

export const Layout: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const [open, setOpen] = useState(false);
    const toggleNavigation = () => setOpen(!open);

    return (
        <div style={{ minHeight: '100vh' }}>
            <div style={{ display: 'flex', minHeight: '100vh' }}>
                <Box component='header'>
                    <Header open={open} toggleNavigation={toggleNavigation} />
                </Box>
                <Navigation open={open} handleClose={toggleNavigation} />
                <Box component='main' sx={{ flexGrow: 1, p: 3, pt: 10 }}>
                    <Box sx={{ height: '100%' }}>
                        {children}
                    </Box>
                </Box>
            </div>
        </div>
    );
};