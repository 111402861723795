import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import auth0 from 'auth0-js'

import { setSession } from '../slices/session'

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: 'openid profile email offline_access',
  responseType: 'token',
})

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APP_API_SERVER_URL || 'http://localhost:8080'}/api/v1`,
  credentials: 'include',
  prepareHeaders: async (headers, { getState }) => {

    const session = (getState() as any).session ?? {}

    if (session.accessToken) {
      headers.set('Access-Control-Allow-Credentials', 'true')
      headers.set('Authorization', `Bearer ${session.accessToken}`)
    }

    return headers
  },
})

const paymentPortalApi = createApi({
  reducerPath: 'paymentPortalApi',
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error?.status === 401) {
      webAuth.logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECTION_URL })
      api.dispatch(setSession(undefined))
    }
    return result
  },
  endpoints: () => ({}),
  tagTypes: ['dashboardData', 'recentPayPerLinkRequests']
})

export default paymentPortalApi

