import { useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const PageLoader: React.FC = () => {
  const theme = useTheme()
  const style = {
    color: theme.palette.primary
  }

  return (
    <div className='max-w-[500px] mx-auto flex flex-col gap-4 items-center h-[calc(100vh-13rem)] place-content-center'>
      <CircularProgress
        style={style}
        disableShrink
      />
    </div>
  )
}

export default PageLoader