import { createTheme, responsiveFontSizes, Theme } from '@mui/material';

export const getAppTheme = (mode: any): Theme => {
	let theme = createTheme({
		palette: {
			mode,
			background: {
				default: "#F8FAFB"
			}
		},
	});
	theme = responsiveFontSizes(theme);
	return theme;
};
