import React, { useContext } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';

import { SignOut, Settings } from '../../Actions';
import { ThemeModeContext } from '../../../contexts';
import { useAuth } from '../../../contexts/AuthContext';

interface MobileMenuProps {
	isMenuOpen: boolean;
	handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
	handleMenuClose: () => void;
	anchorEl: HTMLElement | null;
}

export const MobileMenu = ({ isMenuOpen, handleMenuOpen, handleMenuClose, anchorEl }: MobileMenuProps) => {
	const { toggleThemeMode } = useContext(ThemeModeContext);
	const { signOut } = useAuth()

	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id='primary-search-account-menu-mobile'
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<Box sx={{ textAlign: 'center' }}>
				<MenuItem onClick={toggleThemeMode}>
					Toggle Theme
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<Settings disableTooltip />
					Settings
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<SignOut disableTooltip onClick={signOut} />
					Sign Out
				</MenuItem>
			</Box>
		</Menu>
	);
};
