import { useCallback } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import * as Yup from 'yup'

import { useAlert } from '../../../contexts/AlertContext';
import { useSaveSecretsMutation } from '../../../services/secrets';
import useHandleFormApiErrors from '../../../hooks/useHandleFormApiErrors';

const validationSchema = Yup.object().shape({
    ohipClientId: Yup.string().required("Client Id is required"),
    ohipClientSecret: Yup.string().required("Client Secret is required"),
    ohipAppKey: Yup.string().required("App key is required"),
    ohipHostName: Yup.string().required("Host Name is required"),
    ohipUserName: Yup.string().required("User Name is required"),
    ohipPassword: Yup.string().required("Password is required"),
    ohipTenantCode: Yup.string().required("Tenant Code is required"),
})

type FormValues = {
    ohipClientId: string,
    ohipClientSecret: string,
    ohipAppKey: string,
    ohipHostName: string,
    ohipUserName: string,
    ohipPassword: string,
    ohipTenantCode: string,
}

export const OhipForm = () => {
    const { setAlert } = useAlert()
    const [saveSecrets] = useSaveSecretsMutation({})
    const handleFormApiErrors = useHandleFormApiErrors()

    const handleSubmit = useCallback(async (values: FormValues, { resetForm }) => {
        try {
            const response = await saveSecrets(values)
            if (response.error) {
                handleFormApiErrors({
                    defaultMessage: response.error?.data?.message ?? "Something went wrong please try again.",
                    error: {},
                    showFieldErrorsAsToast: true,
                })
            } else {
                setAlert({
                    description: 'Secrets added successfully.',
                    type: 'success',
                })
            }
            resetForm()
        } catch (error: any) {
            console.log({ error })
        }
    }, [])

    const formik = useFormik({
        validateOnBlur: false,
        initialValues: {
            ohipClientId: '',
            ohipClientSecret: '',
            ohipAppKey: '',
            ohipHostName: '',
            ohipUserName: '',
            ohipPassword: '',
            ohipTenantCode: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    })

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Typography variant='h5'>
                OHIP
            </Typography>
            <Divider />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px'
                }}
            >
                <TextField
                    autoFocus
                    error={Boolean(formik.touched.ohipClientId && formik.errors.ohipClientId)}
                    fullWidth
                    helperText={formik.touched.ohipClientId && formik.errors.ohipClientId}
                    label="Client Id"
                    margin="normal"
                    name="ohipClientId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ohipClientId}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.ohipClientSecret && formik.errors.ohipClientSecret)}
                    fullWidth
                    helperText={formik.touched.ohipClientSecret && formik.errors.ohipClientSecret}
                    label="Client Secret"
                    margin="normal"
                    name="ohipClientSecret"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ohipClientSecret}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.ohipAppKey && formik.errors.ohipAppKey)}
                    fullWidth
                    helperText={formik.touched.ohipAppKey && formik.errors.ohipAppKey}
                    label="App Key"
                    margin="normal"
                    name="ohipAppKey"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ohipAppKey}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.ohipTenantCode && formik.errors.ohipTenantCode)}
                    fullWidth
                    helperText={formik.touched.ohipTenantCode && formik.errors.ohipTenantCode}
                    label="Tenant Code"
                    margin="normal"
                    name="ohipTenantCode"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ohipTenantCode}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.ohipHostName && formik.errors.ohipHostName)}
                    fullWidth
                    helperText={formik.touched.ohipHostName && formik.errors.ohipHostName}
                    label="Host Name"
                    margin="normal"
                    name="ohipHostName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ohipHostName}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.ohipUserName && formik.errors.ohipUserName)}
                    fullWidth
                    helperText={formik.touched.ohipUserName && formik.errors.ohipUserName}
                    label="User Name"
                    margin="normal"
                    name="ohipUserName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ohipUserName}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.ohipPassword && formik.errors.ohipPassword)}
                    fullWidth
                    helperText={formik.touched.ohipPassword && formik.errors.ohipPassword}
                    label="Password"
                    margin="normal"
                    name="ohipPassword"
                    type="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.ohipPassword}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
            </Box>
            <Button
                disabled={formik.isSubmitting}
                size="small"
                type="submit"
                variant="contained"
                sx={{
                    height: '52px',
                    my: 2,
                    p: 2,
                    textTransform: 'none',
                    borderRadius: 3
                }}
            >
                Save OHIP Settings
            </Button>
        </form>
    );
};
