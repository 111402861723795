import { ComponentType } from 'react';
import { Badge, Icon } from '@mui/material';


interface ActionIconProps {
	badgeContent?: number;
	icon: ComponentType;
}

export const ActionIcon = ({ badgeContent, icon }: ActionIconProps) => {
	return (
		<Badge badgeContent={badgeContent} color={'secondary'}>
			<Icon component={icon} />
		</Badge>
	);
};
