import paymentPortalApi from '../../state/api'

const secretsService = paymentPortalApi.injectEndpoints({
    endpoints: build => ({
        saveSecrets: build.mutation({
            query: (data) => ({
                url: '/secrets-vault',
                method: 'PATCH',
                body: data,
            }),
        }),
    }),
})

export default secretsService

export const {
    useSaveSecretsMutation
} = secretsService

export const {
    endpoints: {
        saveSecrets,
    },
} = secretsService
