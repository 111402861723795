import { useCallback, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Link as MUILink, TextField, Button } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Grid from '@mui/material/Grid';

import useHandleFormApiErrors from '../../../hooks/useHandleFormApiErrors'
import AuthLayout from '../../../components/Layout/AuthLayout'
import useSession from '../../../hooks/useSession'
import { useAuth } from '../../../contexts/AuthContext/AuthContextProvider'
import { FormValues } from './types'

function LoginPage() {
  const { user } = useSession();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("invitationId")
  const redirectUri = searchParams.get('redirectUri')

  useEffect(() => {
    if (redirectUri) {
      localStorage.setItem("redirectUri", redirectUri)
      if (user) return navigate(redirectUri, { replace: true })
    } else if (user) navigate('/', { replace: true })
  }, [invitationId, redirectUri, user])

  const handleFormApiErrors = useHandleFormApiErrors()
  const { loginWithEmailPassword } = useAuth()

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  })

  const handleSubmit = useCallback(async (values: FormValues, { setErrors }: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    try {
      await loginWithEmailPassword(values.email, values.password)
    } catch (error: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      console.log('error: ', error)
      handleFormApiErrors({
        defaultMessage: error.error_description,
        error: {},
        setErrors,
        showFieldErrorsAsToast: true,
      })
    }
  }, [])

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <AuthLayout title="Login">
      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          autoFocus
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
          InputProps={{ sx: { borderRadius: '12px' } }}
        />
        <TextField
          error={Boolean(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="Password"
          margin="normal"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password}
          InputProps={{ sx: { borderRadius: '12px' } }}
        />
        <Link to="/forgot-password">
          <MUILink
            sx={{
              width: '100%',
              textAlign: 'end',
              display: 'inline-block',
              textDecoration: 'none',
              color: 'black',
              fontWeight: 400,
              fontSize: '12px'
            }}
            variant="body1"
          >
            Forgot password
          </MUILink>
        </Link>
        <Button
          type="submit"
          fullWidth
          disabled={formik.isSubmitting}
          variant="contained"
          sx={{
            my: 2,
            p: 2,
            textTransform: 'none',
            borderRadius: 3
          }}
        >
          Sign in
        </Button>
        <Grid container justifyContent={"center"} sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
          <Grid item>
            Don't have an account?
            <Link to="/signup">
              <MUILink sx={{ textDecoration: 'none' }}>
                {" Sign Up"}
              </MUILink>
            </Link>
          </Grid>
        </Grid>
      </form>
    </AuthLayout>
  )
}

export default LoginPage
