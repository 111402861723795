/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	TablePagination,
	Typography,
	Modal,
	Box,
	TextField,
	FormControlLabel,
	Checkbox,
	Divider,
	Button
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { getValueFromNestedObject } from '../../utils/getValueFromNestedObject';
import { useGeneratePaymentLinkMutation } from '../../services/saferPay';
import useHandleFormApiErrors from '../../hooks/useHandleFormApiErrors';
import { useAlert } from '../../contexts/AlertContext';
import { Pagination } from '../../types';
import { useLazyGetReservationByIdQuery } from '../../services/reservation';
import PageLoader from '../PageLoader/PageLoader';

type RowData = {
	firstName: string;
	lastName: string;
	name: string;
	reservationDate: string;
	phoneNumber: string;
	reservationId: number;
	"rateAmount.amount": string;
	saferpayLink: string;
	operaStatus: string;
	statusSaferPay: string;
};

const columns: { id: keyof RowData; label: string; minWidth: number; format?: (value: number) => string }[] = [
	{ id: 'name', label: 'Name', minWidth: 170 },
	{ id: 'reservationDate', label: 'Reservation\u00a0Date', minWidth: 100 },
	{
		id: 'phoneNumber',
		label: 'Phone\u00a0Number',
		minWidth: 170,
		format: (value: any) => value.toLocaleString('en-US'),
	},
	{
		id: 'reservationId',
		label: 'Reservation#',
		minWidth: 170,
		format: (value: any) => value.toLocaleString('en-US'),
	},
	{
		id: 'rateAmount.amount',
		label: 'Folio\u00a0Amount',
		minWidth: 170,
		format: (value: any) => value.toFixed(2),
	},
	{ id: 'saferpayLink', label: 'Saferpay Link', minWidth: 50 },
	{ id: 'operaStatus', label: 'Opera Status', minWidth: 50 },
];

const validationSchema = yup.object({
	reservationNumber: yup
		.string()
		.required('Reservation Number is required.'),
	amount: yup.number().moreThan(0, 'Amount must be greater than 0').required('Amount is required.'),
	email: yup
		.string()
		.email('Enter a valid email')
		.required('Email is required'),
	firstName: yup.string().required('First name is required'),
	lastName: yup.string().required('Last name is required'),
	comments: yup.string(),
})

export const ReservationTable = ({
	data = [],
	pagination,
	rowsPerPage,
	page,
	setPage,
	setRowsPerPage,
}: {
	data: RowData[],
	pagination: Pagination,
	rowsPerPage: number,
	page: number,
	setPage: React.Dispatch<React.SetStateAction<number>>,
	setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
}) => {

	const [openGenerateLinkModal, setOpenGenerateLinkModal] = useState(false);
	const [openViewModal, setOpenViewModal] = useState(false);
	const [sendEmail, setSendEmail] = useState(false);
	const [selectedReservation, setSelectedReservation] = useState<RowData | null>(null)
	const [generatePaymentLink] = useGeneratePaymentLinkMutation({})
	const [getReservationById, { currentData: reservation, isFetching }] = useLazyGetReservationByIdQuery({})
	const handleFormApiErrors = useHandleFormApiErrors()
	const { setAlert } = useAlert()

	const handleOpenGenerateLinkModal = (row: RowData) => {
		if (!row.rateAmount.amount) return null
		setSelectedReservation(row)
		setOpenGenerateLinkModal(true)
	};

	const handleCloseGenerateLinkModal = () => {
		setSelectedReservation(null)
		setOpenGenerateLinkModal(false)
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			reservationNumber: selectedReservation?.reservationId ?? '',
			amount: selectedReservation?.rateAmount?.amount ?? 0,
			email: '',
			firstName: selectedReservation?.firstName,
			lastName: selectedReservation?.lastName,
			comments: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => {
			try {
				const data = {
					...values,
					ohipReservationId: values.reservationNumber,
					paymentDescription: values.comments,
					emailConfirm: sendEmail,
				}
				const response = await generatePaymentLink(data)
				console.log({ response })
				if (response?.error) {
					handleFormApiErrors({
						defaultMessage: response.error?.data?.errors?.[0]?.detail ?? "Something went wrong please try again.",
						error: {},
						showFieldErrorsAsToast: true,
					})
				} else {
					setAlert({
						description: `Payment link generated successfully.`,
						type: 'success',
					})

					if (sendEmail) {
						const subject = `Payment Link for Reservation #${values.reservationNumber}`;
						const bodyMessage = "Click or copy this link into your browser to pay for the payment:";
						const bodyContent = `${bodyMessage}\n\n${response.data.RedirectUrl}`;
						const to = values.email

						const mailtoUrl = `mailto:?to=${to}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(bodyContent)}`;

						// Create a temporary anchor element
						const mailtoLink = document.createElement('a');
						mailtoLink.href = mailtoUrl;

						await new Promise(resolve => setTimeout(resolve, 2000))

						document.body.appendChild(mailtoLink);
						mailtoLink.click();
						document.body.removeChild(mailtoLink);
					}

					resetForm()
					handleCloseGenerateLinkModal()
				}
			} catch (error) {
				console.log({ error })
			}
		}
	})

	const handleOpenViewModal = (row: RowData) => {
		getReservationById({ reservationId: row.reservationId })
		setOpenViewModal(true);
		setSelectedReservation(row)
	}

	const handleCloseViewModal = () => {
		setSelectedReservation(null)
		setOpenViewModal(false);
	}

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	const handleCheckBox = () => {
		setSendEmail(!sendEmail);
	}

	return (
		<>
			<TableContainer sx={{ maxHeight: '500px', overflow: 'auto' }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column, colIndex) => (
								<TableCell
									key={`${column.id}_${colIndex}`}
									align='center'
									style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, rowIndex) => {
							return (
								<TableRow hover role="checkbox" tabIndex={-1} key={`${row.reservationDate}_${rowIndex}`}>
									{columns.map((column: { id: keyof RowData; format?: (value: number) => string }, colIndex) => {
										const value = getValueFromNestedObject(row, column.id.split("."));
										return (
											<TableCell key={`${column.id}_${colIndex}`} align='center'>
												{column.id === 'saferpayLink'
													? (
														row.rateAmount.amount && row.statusSaferPay === 'OPEN' ? <Typography
															variant='subtitle2'
															sx={{ textDecoration: 'underline', cursor: 'pointer' }}
															onClick={() => handleOpenGenerateLinkModal(row)}
														>
															Generate
														</Typography> : null)
													: column.id === 'operaStatus'
														? <Typography
															variant='subtitle2'
															sx={{ textDecoration: 'underline', cursor: 'pointer' }}
															onClick={() => handleOpenViewModal(row)}
														>
															{value}
														</Typography>
														: column.format && typeof value === 'number' ? column.format(value) : value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component="div"
				count={pagination?.totalResults ?? 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Modal
				open={openGenerateLinkModal}
				onClose={handleCloseGenerateLinkModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					maxWidth: 900,
					bgcolor: 'background.paper',
					border: '2px solid gray',
					borderRadius: '20px',
					boxShadow: 24,
					p: 4,
				}}>
					<Typography id="modal-modal-title" variant="h5" component="h2" sx={{ fontWeight: 700 }}>
						Payment Link Generator
					</Typography>
					<form
						style={{ display: 'flex', justifyContent: 'space-between' }}
						onSubmit={formik.handleSubmit}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography id="modal-modal-description" variant='h6' sx={{ mt: 2 }}>
								Request details
							</Typography>
							<Box sx={{ display: 'flex', gap: 3 }}>
								<TextField
									disabled
									id='reservation-number'
									name='reservationNumber'
									label='Reservation Number'
									value={formik.values.reservationNumber}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.reservationNumber && Boolean(formik.errors.reservationNumber)}
									helperText={formik.touched.reservationNumber && formik.errors.reservationNumber}
								/>
								<TextField
									disabled
									id='amount'
									name='amount'
									label='Amount $'
									value={formik.values.amount}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									type='number'
									error={formik.touched.amount && Boolean(formik.errors.amount)}
									helperText={formik.touched.amount && formik.errors.amount}
								/>
							</Box>
							<TextField
								id='email'
								name='email'
								label='Email'
								value={formik.values.email}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
							/>
							<Box sx={{ display: 'flex', gap: 3 }}>
								<TextField
									disabled
									id='first-name'
									label='First Name'
									name='firstName'
									value={formik.values.firstName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.firstName && Boolean(formik.errors.firstName)}
									helperText={formik.touched.firstName && formik.errors.firstName}
								/>
								<TextField
									disabled
									id='last-name'
									label='Last Name'
									name='lastName'
									value={formik.values.lastName}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.lastName && Boolean(formik.errors.lastName)}
									helperText={formik.touched.lastName && formik.errors.lastName}
								/>
							</Box>
							<FormControlLabel control={
								<Checkbox checked={sendEmail}
									onChange={handleCheckBox}
									name='sendEmail'
									color='primary'
								/>
							}
								label='Send email confirmation' />
							<Box sx={{ display: 'flex', gap: '20px' }}>
								<Button
									variant="contained"
									color="primary"
									sx={{ width: '160px' }}
									type='submit'
									disabled={formik.isSubmitting}
								>
									Create Link
								</Button>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleCloseGenerateLinkModal}
									sx={{ width: '160px' }}
									disabled={formik.isSubmitting}
								>
									Cancel
								</Button>
							</Box>
						</Box>
						<Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							<Typography id="modal-modal-description" variant='h6' sx={{ mt: 2 }}>
								Comments
							</Typography>
							<TextField
								id='comments'
								name='comments'
								label='Type here'
								value={formik.values.comments}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								multiline
								rows={9}
								error={formik.touched.comments && Boolean(formik.errors.comments)}
								helperText={formik.touched.comments && formik.errors.comments}
							/>
						</Box>
					</form>
				</Box>
			</Modal>

			<Modal
				open={openViewModal}
				onClose={handleCloseViewModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{
					position: 'absolute' as 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					maxWidth: 900,
					minWidth: 500,
					bgcolor: 'background.paper',
					border: '2px solid gray',
					borderRadius: '20px',
					boxShadow: 24,
					p: 6,
				}}>
					{
						isFetching ? <PageLoader />
							: (
								<>
									<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
										<Typography id="modal-modal-title" variant="h5" component="h2" sx={{ fontWeight: 700 }}>
											Payment Link Display
										</Typography>
										<Button
											onClick={handleCloseViewModal}
											sx={{ width: '32px', height: '32px', borderRadius: '20px' }}
										>
											<CloseIcon />
										</Button>
									</Box>
									<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
										<Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
											<Typography id="modal-modal-description" variant='h6' sx={{ mt: 2 }}>
												Request details
											</Typography>
											<Box sx={{ display: 'flex', gap: 3 }}>
												<TextField
													variant='standard'
													defaultValue={selectedReservation?.reservationId}
													label='Reservation Number *'
													InputProps={{ readOnly: true }} />
												<TextField
													variant='standard'
													defaultValue={`$${selectedReservation?.rateAmount?.amount}`}
													label='Amount $'
													InputProps={{ readOnly: true }}
												/>
												<TextField
													variant='standard'
													defaultValue={selectedReservation?.statusSaferPay}
													label='Status Saferpay'
													InputProps={{ readOnly: true }}
												/>
												<TextField
													variant='standard'
													defaultValue={selectedReservation?.operaStatus}
													label='Status Oracle'
													InputProps={{ readOnly: true }}
												/>
											</Box>
											<TextField
												variant='standard'
												defaultValue={reservation?.saferPayLink ?? ''}
												label='URL *'
												InputProps={{ readOnly: true }}
											/>
											<Box sx={{ display: 'flex', gap: 3 }}>
												<TextField variant='standard'
													defaultValue={selectedReservation?.firstName}
													label='First Name'
													InputProps={{ readOnly: true }}
												/>
												<TextField variant=
													'standard'
													defaultValue={selectedReservation?.lastName}
													label='Last Name'
													InputProps={{ readOnly: true }}
												/>
											</Box>
											<Button
												variant="contained"
												color="secondary"
												onClick={handleCloseViewModal}
												sx={{ width: '160px' }}>
												Close
											</Button>
										</Box>
									</Box>
								</>
							)
					}
				</Box>
			</Modal>
		</>
	)
};
