import {
	Home as HomeIcon,
	Search as SearchIcon,
	Settings as SettingIcon,
} from '@mui/icons-material';

import { Route } from '../types/Route';
import { HomePage } from '../pages/Home';
import { SearchPage } from '../pages/Search';
import { SettingsPage } from '../pages/Settings';

const routes: Array<Route> = [
	{
		key: 'router-search',
		title: 'Search',
		description: 'Search',
		component: SearchPage,
		path: '/search',
		isEnabled: true,
		icon: SearchIcon,
		isPrivate: true,
	},
	{
		key: 'router-home',
		title: 'Home',
		description: 'Home',
		component: HomePage,
		path: '/',
		isEnabled: true,
		icon: HomeIcon,
		appendDivider: true,
		isPrivate: true,
	},
	{
		key: 'router-settings',
		title: 'Settings',
		description: 'Settings',
		component: SettingsPage,
		path: '/settings',
		isEnabled: true,
		icon: SettingIcon,
		isPrivate: true,
	},
];

export default routes;
