import paymentPortalApi from '../../state/api'

const saferPayService = paymentPortalApi.injectEndpoints({
    endpoints: build => ({
        generatePaymentLink: build.mutation({
            query: (data) => ({
                url: '/saferpay/PaymentPage/link',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['dashboardData', 'recentPayPerLinkRequests']
        }),
    }),
})

export default saferPayService

export const {
    useGeneratePaymentLinkMutation
} = saferPayService

export const {
    endpoints: {
        generatePaymentLink,
    },
} = saferPayService
