import { useCallback, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link as MUILink, TextField, Button, Grid } from '@mui/material'

import AuthLayout from '../../../components/Layout/AuthLayout'
import useSession from '../../../hooks/useSession'
import useHandleFormApiErrors from '../../../hooks/useHandleFormApiErrors'
import { useAuth } from '../../../contexts/AuthContext/AuthContextProvider'
import { useAlert } from '../../../contexts/AlertContext'
import { FormValues } from './types'

const defaultSignUpErrorMessage = 'Something went wrong while creating your account. Please contact support.'

function SignUpPage() {
  const { user } = useSession()
  const navigate = useNavigate()
  const { setAlert } = useAlert()

  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get("invitationId")
  const redirectUri = searchParams.get('redirectUri')

  useEffect(() => {
    if (redirectUri) {
      localStorage.setItem("redirectUri", redirectUri)
      if (user) return navigate(redirectUri, { replace: true })
    } else if (user) navigate('/', { replace: true })
  }, [invitationId, redirectUri, user])

  const handleFormApiErrors = useHandleFormApiErrors()
  const { signUp } = useAuth()

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  })

  const handleSubmit = useCallback(async (values: FormValues, { setErrors }: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    try {
      await signUp(values.username, values.email, values.password)
      setAlert({
        type: 'success',
        description: 'A verification email was sent to your email inbox. Please verify your email address.',
      })
      navigate('/signin')
    } catch (error: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      if (['invalid_password', 'invalid_signup', 'extensibility_error', 'user_exists'].includes(error.code)) {
        handleFormApiErrors({
          defaultMessage: (error.original?.response?.body?.message || error.original?.response?.body?.description) || defaultSignUpErrorMessage,
          error: {},
          setErrors,
          showFieldErrorsAsToast: true,
        })
      }
    }
  }, [])

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <AuthLayout title="Register">
      <form
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <TextField
          autoFocus
          error={Boolean(formik.touched.username && formik.errors.username)}
          fullWidth
          helperText={formik.touched.username && formik.errors.username}
          label="Username"
          margin="normal"
          name="username"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.username}
          InputProps={{ sx: { borderRadius: '12px' } }}
        />
        <TextField
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
          InputProps={{ sx: { borderRadius: '12px' } }}
        />
        <TextField
          error={Boolean(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="Password"
          margin="normal"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="password"
          value={formik.values.password}
          InputProps={{ sx: { borderRadius: '12px' } }}
        />
        <TextField
          error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
          fullWidth
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          label="Confirm Password"
          margin="normal"
          name="confirmPassword"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="password"
          value={formik.values.confirmPassword}
          InputProps={{ sx: { borderRadius: '12px' } }}
        />
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            my: 2,
            p: 2,
            textTransform: 'none',
            borderRadius: 3
          }}
        >
          Register
        </Button>
      </form>
      <Grid container justifyContent={"center"} sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
        <Grid item>
          Already have an account?
          <Link to="/signin">
            <MUILink sx={{ textDecoration: 'none' }}>
              {" Log in"}
            </MUILink>
          </Link>
        </Grid>
      </Grid>
    </AuthLayout>
  )
}

export default SignUpPage
