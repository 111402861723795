import React from 'react';
import type { CSSProperties } from "react";
import type { EChartsOption } from "echarts";
import ReactECharts from 'echarts-for-react';

export interface ChartProps {
    option: EChartsOption;
    notMerge?: boolean;
    lazyUpdate?: boolean;
    style?: CSSProperties;
    loading?: boolean;
    opts?: Record<string, string>
    theme?: "light" | "dark";
}

const Chart: React.FC<ChartProps> = ({
    option,
    notMerge = true,
    lazyUpdate = true,
    opts = { renderer: 'svg' },
    style = { height: '300px', width: '100%' },
    theme = "light",
}) => {
    return <ReactECharts
        option={option}
        notMerge={notMerge}
        lazyUpdate={lazyUpdate}
        style={style}
        opts={opts}
        theme={theme}
    />
}

export default Chart;
