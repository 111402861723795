import { useCallback } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import * as Yup from 'yup'

import { useAlert } from '../../../contexts/AlertContext';
import { useSaveSecretsMutation } from '../../../services/secrets';
import useHandleFormApiErrors from '../../../hooks/useHandleFormApiErrors';

const validationSchema = Yup.object().shape({
    saferPayUrl: Yup.string().required("Url is required"),
    saferPayUsername: Yup.string().required("Username is required"),
    saferPayPassword: Yup.string().required("Password is required"),
    saferPayCustomerId: Yup.string().required("Customer Id is required"),
    saferPayTerminalId: Yup.string().required("Terminal Id is required"),
})

type FormValues = {
    saferPayUrl: string,
    saferPayUsername: string,
    saferPayPassword: string,
    saferPayCustomerId: string,
    saferPayTerminalId: string,
}

export const SaferPayForm = () => {
    const { setAlert } = useAlert()
    const [saveSecrets] = useSaveSecretsMutation({})
    const handleFormApiErrors = useHandleFormApiErrors()

    const handleSubmit = useCallback(async (values: FormValues, { resetForm }) => {
        try {
            const response = await saveSecrets(values)
            if (response.error) {
                handleFormApiErrors({
                    defaultMessage: response.error?.data?.message ?? "Something went wrong please try again.",
                    error: {},
                    showFieldErrorsAsToast: true,
                })
            } else {
                setAlert({
                    description: 'Secrets added successfully.',
                    type: 'success',
                })
            }
            resetForm()
        } catch (error: any) {
            console.log({ error })
        }
    }, [])

    const formik = useFormik({
        validateOnBlur: false,
        initialValues: {
            saferPayUrl: '',
            saferPayUsername: '',
            saferPayPassword: '',
            saferPayCustomerId: '',
            saferPayTerminalId: '',
        },
        validationSchema,
        onSubmit: handleSubmit,
    })

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Typography variant='h5' sx={{ pt: 1, mt: 2 }}>
                Safer pay
            </Typography>
            <Divider />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px'
                }}
            >
                <TextField
                    error={Boolean(formik.touched.saferPayUrl && formik.errors.saferPayUrl)}
                    fullWidth
                    helperText={formik.touched.saferPayUrl && formik.errors.saferPayUrl}
                    label="Safer Pay Url"
                    margin="normal"
                    name="saferPayUrl"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.saferPayUrl}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.saferPayCustomerId && formik.errors.saferPayCustomerId)}
                    fullWidth
                    helperText={formik.touched.saferPayCustomerId && formik.errors.saferPayCustomerId}
                    label="Safer Pay Customer Id"
                    margin="normal"
                    name="saferPayCustomerId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.saferPayCustomerId}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.saferPayUsername && formik.errors.saferPayUsername)}
                    fullWidth
                    helperText={formik.touched.saferPayUsername && formik.errors.saferPayUsername}
                    label="Safer Pay Username"
                    margin="normal"
                    name="saferPayUsername"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.saferPayUsername}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.saferPayPassword && formik.errors.saferPayPassword)}
                    fullWidth
                    helperText={formik.touched.saferPayPassword && formik.errors.saferPayPassword}
                    label="Safer Pay Password"
                    margin="normal"
                    name="saferPayPassword"
                    type="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.saferPayPassword}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
                <TextField
                    error={Boolean(formik.touched.saferPayTerminalId && formik.errors.saferPayTerminalId)}
                    fullWidth
                    helperText={formik.touched.saferPayTerminalId && formik.errors.saferPayTerminalId}
                    label="Safer Pay Terminal Id"
                    margin="normal"
                    name="saferPayTerminalId"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.saferPayTerminalId}
                    InputProps={{ sx: { borderRadius: '12px' } }}
                />
            </Box>
            <Button
                disabled={formik.isSubmitting}
                size="small"
                type="submit"
                variant="contained"
                sx={{
                    height: '52px',
                    my: 2,
                    p: 2,
                    textTransform: 'none',
                    borderRadius: 3
                }}
            >
                Save Safer pay Settings
            </Button>
        </form>
    );
};
