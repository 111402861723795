import paymentPortalApi from '../../state/api'
import { objectToQueryString } from '../../utils/objectToQueryString';

type GetReservationsRequestPayload = {
  offset?: number;
  limit?: number;
  surname?: string;
  reservationId?: string;
}

type RecentPayPerLinkRequestsRequestPayload = {
  pageSize?: number;
  pageNumber?: number;
}

const reservationService = paymentPortalApi.injectEndpoints({
  endpoints: build => ({
    getReservations: build.query<any, GetReservationsRequestPayload>({
      query: ({ surname, reservationId, offset, limit }) => ({
        url: `/ohip/reservations?${objectToQueryString({ surname, reservationId, offset, limit })}`,
        method: 'GET',
      }),
    }),
    getDashboardData: build.query({
      query: () => ({
        url: `/reservations/dashboard/data`,
        method: 'GET',
      }),
      providesTags: ['dashboardData']
    }),
    getRecentPayPerLinkRequests: build.query<any, RecentPayPerLinkRequestsRequestPayload>({
      query: ({ pageSize, pageNumber }) => ({
        url: `/reservations/dashboard/table-data?${objectToQueryString({ pageSize, pageNumber })}`,
        method: 'GET',
      }),
      providesTags: ['recentPayPerLinkRequests']
    }),
    getReservationById: build.query({
      query: ({ reservationId }) => ({
        url: `/reservations/${reservationId}`,
        method: 'GET',
      }),
    }),
  }),
})

export default reservationService

export const {
  useLazyGetReservationsQuery,
  useGetDashboardDataQuery,
  useLazyGetRecentPayPerLinkRequestsQuery,
  useLazyGetReservationByIdQuery,
} = reservationService

export const {
  endpoints: {
    getReservations,
    getDashboardData,
    getRecentPayPerLinkRequests,
    getReservationById,
  },
} = reservationService
