/** @jsxImportSource @emotion/react */
import { Typography } from '@mui/material';

export const PageTitle = ({ title }: { title: string }) => (
	<Typography
		sx={{ textTransform: 'uppercase' }}
		variant='h4'
		component='h3'
		color='textSecondary'
	>
		{title}
	</Typography>
);
