import { useCallback, useMemo, createContext, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import auth0, { Auth0DecodedHash, Auth0Error, Auth0ParseHashError } from 'auth0-js'

import { setSession } from '../../hooks/useSession'
import useSession from '../../hooks/useSession'

import { Props } from './types'

const DATABASE_CONNECTION = process.env.REACT_APP_AUTH0_DATABASE_CONNECTION

const AuthContext = createContext<{
  getSession: () => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  loginWithEmailPassword: (email: string, password: string) => Promise<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  getAuthInfo: () => Promise<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  getUser: (accessToken: string) => Promise<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  resetPassword: (email: string) => Promise<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  signUp: (username: string, email: string, password: string) => Promise<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  signOut: () => void,
}>(undefined as any) // eslint-disable-line @typescript-eslint/no-explicit-any

function AuthContextProvider({ children }: Props) {
  const dispatch = useDispatch()
  const session = useSession()

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     // polling silent access token refresh here, do it inside AuthContext
  //     // session slice accessToken is the single source of trust, we don't want to manage access token from other parts of code
  //     if (session?.accessToken) {
  //       const auth0Session = await getSession()
  //       setSession({ ...session, ...(auth0Session as any) })
  //     }
  //   }, 300 * 1000);  // test purpose, increase to 1hr on production

  //   return (() => clearInterval(interval));
  // }, [])

  const webAuth = useMemo(() => new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: 'openid profile email offline_access',
    responseType: 'token',
  }), [])

  const getSession = () => {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, function (err, authResult) {
        if (err) {
          return reject(err)
        }

        resolve(authResult)
      })
    })
  }

  const loginWithEmailPassword = useCallback((email: string, password: string) => {
    const urlParams = new URLSearchParams(window.location.search)
    const stateParam = urlParams.get('state') || ''

    return new Promise((resolve, reject) => {
      webAuth.login({
        email,
        password,
        realm: DATABASE_CONNECTION,
        state: stateParam,
      }, (error: Auth0Error | null, result) => {
        if (error) {
          return reject(error)
        }

        resolve(result)
      })
    })
  }, [webAuth])

  const signOut = useCallback(() => {
    webAuth.logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECTION_URL })
    dispatch(setSession(undefined))
  }, [webAuth])

  const getAuthInfo = useCallback(() => {
    return new Promise((resolve, reject) => {
      webAuth.parseHash({ hash: window.location.hash }, function (err: Auth0ParseHashError | null, authResult: Auth0DecodedHash | null) {
        if (err) {
          return reject(err)
        }
        // It can include the following:
        // authResult.accessToken - access token for the API specified by `audience`
        // authResult.expiresIn - string with the access token's expiration time in seconds
        // authResult.idToken - ID token JWT containing user profile information
        resolve(authResult)
      })
    })
  }, [webAuth])

  const getUser = useCallback((accessToken: string) => {
    return new Promise((resolve, reject) => {
      webAuth.client.userInfo(accessToken, function (err, user) {
        if (err) {
          return reject(err)
        }

        resolve(user)
      })
    })
  }, [webAuth])

  const resetPassword = useCallback((email: string) => {
    return new Promise((resolve, reject) => {
      webAuth.changePassword({
        email,
        connection: DATABASE_CONNECTION || '',
      }, (error, result) => {
        if (error) {
          reject(error)
          return
        }

        resolve(result)
      })
    })
  }, [webAuth])

  const signUp = useCallback((username: string, email: string, password: string) => {
    return new Promise((resolve, reject) => {
      webAuth.signup({
        connection: DATABASE_CONNECTION || '',
        password: password,
        username,
        email,
      },
        (error, result) => {
          if (error) {
            reject(error)
            return
          }
          resolve(result)
        })
    })
  }, [webAuth])

  const value = useMemo(
    () => ({
      getSession,
      loginWithEmailPassword,
      getAuthInfo,
      getUser,
      resetPassword,
      signUp,
      signOut,
    }),
    [
      getSession,
      loginWithEmailPassword,
      getUser,
      getAuthInfo,
      resetPassword,
      signUp,
      signOut,
    ])

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider

export const useAuth = () => useContext(AuthContext)
