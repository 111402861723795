import { Divider, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

import { Settings, SignOut } from '../../Actions';
import { useAuth } from '../../../contexts/AuthContext';
import useSession from '../../../hooks/useSession';

interface DefaultMenuProps {
	isMenuOpen: boolean;
	handleMenuClose: () => void;
	anchorEl: HTMLElement | null;
}

export const DefaultMenu = ({ isMenuOpen, handleMenuClose, anchorEl }: DefaultMenuProps) => {
	const { signOut } = useAuth()
	const { user } = useSession()
	return (
		<Menu
			anchorEl={anchorEl}
			id='primary-search-account-menu'
			keepMounted
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{
				user?.hasOrganization && (
					<div>
						<Link to="/settings">
							<MenuItem>
								<Settings disableTooltip />
								Settings
							</MenuItem>
						</Link>
						<Divider />
					</div>
				)
			}
			<MenuItem onClick={signOut}>
				<SignOut disableTooltip />
				Sign Out
			</MenuItem>
		</Menu>
	)
};
