import { useEffect, useMemo, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import DashboardCard from "./DashboardCard";
import { useGetDashboardDataQuery, useLazyGetRecentPayPerLinkRequestsQuery } from "../../services/reservation";
import PageLoader from "../PageLoader/PageLoader";
import { formatCurrency } from "../../utils/formatCurrency";
import { PayPerLinkRequestsTable } from "../PayPerLinkRequestsTable";
import useHandleFormApiErrors from "../../hooks/useHandleFormApiErrors";
import { formatNumber } from "../../utils/formatNumber";
import Chart from "../Chart";

const xLabels = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

const Dashboard = () => {
  const { data, error } = useGetDashboardDataQuery({})
  const [getRecentPayPerLinkRequests, { data: recentPayPerLinkRequests, isFetching }] = useLazyGetRecentPayPerLinkRequestsQuery({})
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const handleFormApiErrors = useHandleFormApiErrors()

  useEffect(() => {
    const triggerGetRecentPayPerLinkRequests = async () => {
      await getRecentPayPerLinkRequests({
        pageSize: rowsPerPage,
        pageNumber: page + 1
      })
      setIsLoading(false)
    }
    triggerGetRecentPayPerLinkRequests()
  }, [rowsPerPage, page])

  useEffect(() => {
    if (error) {
      handleFormApiErrors({
        defaultMessage: "Something went wrong",
        error: {},
      })
    }
  }, [error])

  const {
    pieChartData,
    cardsData,
    lineChartData
  } = data?.reservationsCalculatedData ?? {}

  const Data = useMemo(() => {
    if (!cardsData) return []

    const {
      totalGenerated,
      totalPaid,
      totalOpen,
      openRate,
    } = cardsData

    return [
      {
        heading: "Total Requests",
        description: `Links Generated: ${totalGenerated?.linksGenerated || 0}`,
        icon: <LocalMallIcon />,
        value: formatCurrency(totalGenerated?.total ?? 0),
        styles: { backgroundColor: "#C61FDF" }
      },
      {
        heading: "Total Paid",
        description: `Links Paid: ${totalPaid?.linksPaid || 0}`,
        icon: <MonetizationOnIcon />,
        value: formatCurrency(totalPaid?.total ?? 0),
        styles: { backgroundColor: "#00A0B0" }
      },
      {
        heading: "Total Open",
        description: `Open Links: ${totalOpen?.linksOpen || 0}`,
        icon: <PeopleAltIcon />,
        value: formatCurrency(totalOpen?.total ?? 0),
        styles: { backgroundColor: "#FDAB3D" }
      },
      {
        heading: "Open Rate",
        icon: <HowToRegIcon />,
        value: `${formatNumber(openRate?.percentage)}%`,
        styles: { backgroundColor: "#A650FB" }
      },
    ]
  }, [cardsData])

  if (isLoading) return <PageLoader />

  return (
    <Grid container spacing={3}>
      {Data.map((item, index) => (
        <Grid item xs={3} sx={{ marginTop: 5, marginBottom: 5 }} key={index}>
          <DashboardCard dataItem={item} />
        </Grid>
      ))}
      <Grid item xs={8}>
        <Card sx={{ height: 320, borderRadius: 3 }}>
          <CardContent>
            <Chart
              option={{
                title: {
                  text: 'Link Performance'
                },
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['Generated', 'Paid', 'Voided', 'Open'],
                  icon: 'square',
                  top: 40,
                  itemGap: 50
                },
                grid: {
                  top: '30%',
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                toolbox: {
                  feature: {
                    saveAsImage: {}
                  }
                },
                xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: xLabels
                },
                yAxis: {
                  type: 'value'
                },
                series: [
                  {
                    name: 'Generated',
                    type: 'line',
                    smooth: true,
                    data: lineChartData?.statusCounts?.generated,
                  },
                  {
                    name: 'Paid',
                    type: 'line',
                    smooth: true,
                    data: lineChartData?.statusCounts?.paid,
                  },
                  {
                    name: 'Voided',
                    type: 'line',
                    smooth: true,
                    data: lineChartData?.statusCounts?.voided,
                  },
                  {
                    name: 'Open',
                    type: 'line',
                    smooth: true,
                    data: lineChartData?.statusCounts?.open,
                  }
                ]
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ height: 320, borderRadius: 3 }}>
          <CardContent>
            <Chart
              option={{
                title: {
                  text: 'Link Statuses',
                },
                tooltip: {
                  trigger: 'item'
                },
                legend: {
                  data: ['Paid', 'Open', 'Voided'],
                  icon: 'square',
                  left: 'center',
                  top: 40,
                  itemGap: 30
                },
                series: [
                  {
                    name: 'Link Statuses',
                    type: 'pie',
                    top: 35,
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                      borderRadius: 10,
                      borderColor: '#fff',
                      borderWidth: 2
                    },
                    label: {
                      show: false,
                      position: 'center'
                    },
                    emphasis: {
                      label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                      }
                    },
                    labelLine: {
                      show: false
                    },
                    data: pieChartData
                  }
                ]
              }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <PayPerLinkRequestsTable
          title={"Recent Pay Per Link Requests"}
          isLoading={isFetching}
          rows={recentPayPerLinkRequests?.reservations?.reservationInfo ?? []}
          pagination={recentPayPerLinkRequests?.reservations?.pagination}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
