import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid';

import logo from "../../assets/images/logo-login-without-bg.png";

function AuthLayout({
    title,
    children
}: {
    title: string,
    children: ReactNode
}) {
    return (
        <>
            <Helmet>
                <title>JunoFront | {title}</title>
            </Helmet>
            <Box
                sx={{
                    height: '100vh',
                    mx: 'auto',
                    px: { xs: '2rem', sm: '2rem', lg: '8rem' },
                    py: { xs: '2rem', sm: '2rem', lg: '3rem' },
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box component="img" src={logo} alt="logo" height={60} sx={{ mx: { xs: 'auto', lg: '0' } }} />
                <Grid
                    container
                    component="main"
                    sx={{
                        mx: 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <Grid sx={{ backgroundColor: '#F8FAFB', display: { xs: 'flex', lg: 'block' }, justifyContent: 'center' }} item xs={12} md={6}>
                        <Box
                            sx={{
                                my: 8,
                                width: '443px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                borderRadius: '24px',
                                padding: '3rem',
                                boxShadow: '0px 2px 8px 0px #2632380A'
                            }}
                        >
                            <Typography component="h1" variant="h5" sx={{ fontWeight: 700 }}>
                                {title}
                            </Typography>
                            {children}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        sx={{
                            mx: 'auto',
                            display: { xs: 'none', lg: 'block' }
                        }}
                    >
                        <Box
                            component={"img"}
                            src="./auth.svg"
                            width={600}
                            height={600}
                            sx={{ margin: 'auto' }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default AuthLayout
