/** @jsxImportSource @emotion/react */
import ExitToApp from '@mui/icons-material/ExitToApp';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth } from '../../../../contexts/AuthContext';

export const SignOutRoute = () => {
	const { signOut } = useAuth()

	return (
		<ListItemButton
			sx={{ position: 'absolute', bottom: 0, width: '100%' }}
			onClick={signOut}
		>
			<ListItemIcon>
				<IconButton size='small'>
					<ExitToApp sx={{ color: 'white' }} />
				</IconButton>
			</ListItemIcon>
			<ListItemText primary='Sign Out' sx={{ color: 'white' }} />
		</ListItemButton>
	);
};
