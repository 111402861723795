import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom'

import PageLoader from '../../../components/PageLoader/PageLoader'

import useSession, { setSession } from '../../../hooks/useSession'
import { useAuth } from "../../../contexts/AuthContext"

import { Props } from './types'
import useHandleFormApiErrors from '../../../hooks/useHandleFormApiErrors'

function AuthSuccessRedirect({ children }: Props): React.ReactElement {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useSession()
  const handleFormApiErrors = useHandleFormApiErrors()
  const { getUser, getAuthInfo } = useAuth()

  useEffect(() => {
    const getAuth = async () => {
      try {
        const authResult: any = await getAuthInfo()  // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!authResult) {
          return
        }
        if (authResult.accessToken) {
          const user: any = await getUser(authResult.accessToken) // eslint-disable-line @typescript-eslint/no-explicit-any
          dispatch(setSession({
            ...authResult,
            user,
          }))
        }
      } catch (error) {
        console.log({ error })
        if (error?.errorDescription && error.error !== "invalid_token") {
          handleFormApiErrors({
            error: {},
            defaultMessage: error.errorDescription ?? "Something went wrong, please try again.",
            showFieldErrorsAsToast: true,
          })
        }
      }
    }

    getAuth()
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!user) {
        navigate('/signin')
      }
    }, 2000);

    return () => clearTimeout(timeoutId)
  }, [user]);


  if (user) {
    const redirectUri = localStorage.getItem("redirectUri") || '/'
    localStorage.removeItem("redirectUri")
    return (
      <Navigate
        to={redirectUri}
        state={{ from: location }}
      />
    )
  } else {
    return <PageLoader />
  }

  return children || <Outlet />
}

export default React.memo(AuthSuccessRedirect)
