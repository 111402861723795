/** @jsxImportSource @emotion/react */
import { Typography, Box } from '@mui/material';
import logo from "../../../assets/images/logo-without-bg.png";

export const AppTitle = () => (
	<Box
		sx={{ display: 'flex', alignItems: 'center', gap: '20px', padding: '20px 30px', textDecoration: 'none', color: 'inherit' }}
	>
		<Box component="img" src={logo} alt="logo" height={35} />
		<Typography
			variant='h6'
			noWrap
			sx={{ display: { xs: 'none', sm: 'block' }, color: 'black' }}
		>
			Juno Front Desk - Pay Per Link Generator
		</Typography>
	</Box>
);
