import { Box, Card, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

import { OhipForm } from '../components/views/settings/OhipForm';
import { SaferPayForm } from '../components/views/settings/SaferPayForm';

export const SettingsPage = () => {
    return (
        <>
            <Helmet>
                <title>JunoFront | Settings</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <Container
                    maxWidth="md"
                    sx={{
                        py: { xs: '60px' }
                    }}
                >
                    <Card
                        elevation={2}
                        sx={{ p: 4, borderRadius: '12px', overflow: 'hidden' }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                mt: 1
                            }}
                        >
                            <Typography variant='body2' color='textSecondary' mb={3}>* For security reasons, your saved credentials won't be loaded here, You can always enter and save new credentials.</Typography>
                            <OhipForm />
                            <SaferPayForm />
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};
