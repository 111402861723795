import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, TextField, Link as MUILink, Grid } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import AuthLayout from '../../../components/Layout/AuthLayout'
import useSession from '../../../hooks/useSession'
import { useAuth } from '../../../contexts/AuthContext/AuthContextProvider'
import { useAlert } from '../../../contexts/AlertContext'
import { FormValues } from './types'

function ForgotPasswordPage() {
  const { user } = useSession();
  const { setAlert } = useAlert()
  const { resetPassword } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (user) navigate('/', { replace: true })
  }, [user])

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  })

  const handleSubmit = async (values: FormValues) => {
    try {
      await resetPassword(values.email)
      setAlert({
        description: `We've just sent you an email to reset your password.`,
        type: 'success',
      })
    } catch (err: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      setAlert({
        description: err.message,
        type: 'success',
      })
    }
  }

  const formik = useFormik({
    validateOnBlur: false,
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <AuthLayout title="Password Recovery">
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ width: '100%' }}
      >
        <TextField
          autoFocus
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
          InputProps={{ sx: { borderRadius: '12px' } }}
        />
        <Button
          disabled={formik.isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{
            my: 2,
            p: 2,
            textTransform: 'none',
            borderRadius: 3
          }}
        >
          Recover Password
        </Button>
      </Box>
      <Grid container justifyContent={"center"} sx={{ fontSize: { xs: '14px', sm: '16px' } }}>
        <Grid item>
          <Link to="/signin">
            <MUILink sx={{ textDecoration: 'none' }}>
              Back to login
            </MUILink>
          </Link>
        </Grid>
      </Grid>
    </AuthLayout>
  )
}

export default ForgotPasswordPage
