import React, { useMemo, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux'

import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Layout } from './components/Layout';
import { PageDefault } from './components/PageDefault';
import { useAlert } from "./contexts/AlertContext"
import { AuthContextProvider } from './contexts/AuthContext'

import { ThemeModeContext } from './contexts';
import { routes } from './config';
import { Route as AppRoute } from './types';
import { getAppTheme } from './styles/theme';
import store from './state'
import RequireSignIn from './router/Router/RequireSignIn';
import LoginPage from './pages/auth/Login/Login';
import SignUpPage from './pages/auth/SignUp/SignUp';
import ForgotPasswordPage from './pages/auth/ForgotPassword/ForgotPassword';
import AuthCallback from './router/Router/AuthCallback';
import Error404Page from './pages/error/Error404';
import NoOrganizationPage from './pages/NoOrganization';
import Alert from './components/Alert';

const DARK_MODE_THEME = 'dark';
const LIGHT_MODE_THEME = 'light';

function App() {
  const [mode, setMode] = useState<typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME>(LIGHT_MODE_THEME);
  const { alert, setAlert } = useAlert()

  const themeMode = useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode((prevMode) => (prevMode === LIGHT_MODE_THEME ? DARK_MODE_THEME : LIGHT_MODE_THEME));
      },
    }),
    []
  );

  const theme = useMemo(() => getAppTheme(mode), [mode]);

  return (
    <ReduxProvider store={store}>
      <AuthContextProvider>
        <ThemeModeContext.Provider value={themeMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Routes>
                <Route path='/auth-callback' element={<AuthCallback />} />
                {routes.map((route: AppRoute) => {
                  const Component = route.component || PageDefault
                  return (
                    <Route
                      key={route.key}
                      path={route.path}
                      element={
                        route.isPrivate ? (
                          <RequireSignIn>
                            <Layout>
                              <Component />
                            </Layout>
                          </RequireSignIn>
                        ) : (
                          <Layout>
                            <Component />
                          </Layout>
                        )
                      }
                    />
                  )
                })}
                <Route path='/signin' element={<LoginPage />} />
                <Route path='signup' element={<SignUpPage />} />
                <Route path='forgot-password' element={<ForgotPasswordPage />} />
                <Route path='/no-organization' element={<NoOrganizationPage />} />
                <Route path='*' element={<Error404Page />} />
              </Routes>
            </Router>
            {alert ? (
              <Alert
                openError={true}
                onClose={() => {
                  setAlert(undefined)
                }}
                type={alert.type}
                description={alert.description}
              />
            ) : undefined}
          </ThemeProvider>
        </ThemeModeContext.Provider>
      </AuthContextProvider>
    </ReduxProvider>
  );
}

export default App;
