/** @jsxImportSource @emotion/react */
import { ChangeEvent, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TablePagination,
  Typography,
  Modal,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';

import PageLoader from "../../PageLoader/PageLoader";
import { Pagination } from "../../../types";
import { formatCurrency } from "../../../utils/formatCurrency";

type Column = {
  id: keyof RowData;
  label: string;
  minWidth: number;
  format?: (value: any) => string;
};

type RowData = Record<string, any>;

type Props = {
  title: string;
  columns: Column[];
  rows: RowData[];
  isLoading: boolean;
  pagination: Pagination,
  rowsPerPage: number,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
};

export const CustomTable: React.FC<Props> = ({
  title,
  columns,
  rows,
  isLoading,
  pagination,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RowData | null>(null)

  const handleOpen = (row: RowData) => {
    setSelectedRow(row)
    setOpen(true)
  };

  const handleClose = () => {
    setSelectedRow(null)
    setOpen(false)
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  console.log({ selectedRow })
  return (
    <Paper sx={{ width: '100%', mb: 2, mt: 4, borderRadius: '12px', overflow: 'hidden' }}>
      <Typography
        sx={{ pl: 3, paddingY: 2 }}
        variant='h5'
      >
        {title}
      </Typography>
      {
        isLoading
          ? <PageLoader />
          : <>
            <TableContainer sx={{ maxHeight: '500px', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIndex) => (
                    <TableRow key={rowIndex} hover role="checkbox" tabIndex={-1}>
                      {columns.map((column) => {
                        const value = column.id === 'name' ? `${row["firstName"]} ${row["lastName"]}` : row[column.id];
                        return (
                          <TableCell key={column.id} align="center">
                            {column.id === "saferPayLink" ? (
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOpen(row)}
                              >
                                View
                              </Typography>
                            ) : column.format ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={pagination?.totalResults ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={false}
            />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 900,
                bgcolor: 'background.paper',
                border: '2px solid gray',
                borderRadius: '20px',
                boxShadow: 24,
                p: 6,
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ fontWeight: 700 }}>
                    Payment Link Display
                  </Typography>
                  <Button
                    onClick={handleClose}
                    sx={{ width: '32px', height: '32px', borderRadius: '20px' }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Typography id="modal-modal-description" variant='h6' sx={{ mt: 2 }}>
                      Request details
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 3 }}>
                      <TextField variant='standard' defaultValue={selectedRow?.reservationId ?? ''} label='Reservation Number *' InputProps={{ readOnly: true }} />
                      <TextField variant='standard' defaultValue={formatCurrency(selectedRow?.amount ?? 0)} label='Amount $' InputProps={{ readOnly: true }} />
                      <TextField variant='standard' defaultValue={selectedRow?.statusSaferPay ?? ' '} label='Status Saferpay' InputProps={{ readOnly: true }} />
                      <TextField variant='standard' defaultValue={selectedRow?.statusOracle ?? ' '} label='Status Oracle' InputProps={{ readOnly: true }} />
                    </Box>
                    <TextField variant='standard' defaultValue={selectedRow?.saferPayLink ?? ''} label='URL *' InputProps={{ readOnly: true }} />
                    <Box sx={{ display: 'flex', gap: 3 }}>
                      <TextField variant='standard' defaultValue={selectedRow?.firstName ?? ''} label='First Name' InputProps={{ readOnly: true }} />
                      <TextField variant='standard' defaultValue={selectedRow?.lastName ?? ''} label='Last Name' InputProps={{ readOnly: true }} />
                    </Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleClose}
                      sx={{ width: '160px' }}>
                      Close
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </>
      }
    </Paper>
  );
};
