import { useState } from 'react';
import { Divide as HamburgerMenu } from 'hamburger-react';
import { Box } from '@mui/material';

interface HamburgerProps {
	toggleNavigation: () => void;
}

export const Hamburger = ({ toggleNavigation }: HamburgerProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
		toggleNavigation();
	};

	return <Box sx={{ pl: 1, backgroundColor: '#0F72D7', height: '100%', width: isOpen ? '99.7%': '98%' }}>
		<HamburgerMenu
			size={24}
			onToggle={toggle}
			toggled={isOpen}
		/>
	</Box>;
};
