import { List } from '@mui/material';

import { RouteItem } from './RouteItem';
import { SignOutRoute } from './SignOutRoute';

import { routes } from '../../../config';
import { Route } from '../../../types';

export const Routes = () => {
	return (
		<>
			<List component='nav' sx={{ height: '100%', backgroundColor: '#0F72D7' }}>
				{routes.map((route: Route) => (
					<RouteItem key={route.key} route={route} />
				))}
			</List>
			<SignOutRoute />
		</>
	);
};
