import { CustomTable } from "../ui/CustomTable/";
import { Pagination } from "../../types";
import { RowData } from "./types";

export const columns: {
  id: keyof RowData;
  label: string;
  minWidth: number;
  format?: (value: any) => string;
}[] = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "reservationDate", label: "Reservation\u00a0Date", minWidth: 100 },
    { id: "phoneNumber", label: "Phone\u00a0Number", minWidth: 170 },
    { id: "reservationId", label: "Reservation#", minWidth: 170 },
    { id: "statusSaferPay", label: "Status", minWidth: 170 },
    { id: "saferPayLink", label: "Saferpay Link", minWidth: 50 },
    { id: "operaStatus", label: "Opera Status", minWidth: 50 },
  ];

interface PayPerLinkRequestsTableProps {
  title: string;
  isLoading: boolean;
  rows: Record<string, any>[]
  pagination: Pagination,
  rowsPerPage: number,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
}

export const PayPerLinkRequestsTable: React.FC<
  PayPerLinkRequestsTableProps
> = (props) => {
  return <CustomTable
    columns={columns}
    {...props}
  />;
};
