/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import * as Yup from 'yup'

import { ReservationTable } from '../components/ReservationTable';
import { useLazyGetReservationsQuery } from '../services/reservation';
import PageLoader from '../components/PageLoader/PageLoader';
import useHandleFormApiErrors from '../hooks/useHandleFormApiErrors';

const validationSchema = Yup.object().shape({
	reservationId: Yup.string(),
	surname: Yup.string(),
})

type FormValues = {
	reservationId: string,
	surname: string,
}

export const SearchPage = () => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isLoading, setIsLoading] = useState(true);
	const [getReservations, { data, error, isFetching }] = useLazyGetReservationsQuery({});
	const handleFormApiErrors = useHandleFormApiErrors()

	useEffect(() => {
		if (error?.data) {
			handleFormApiErrors({
				defaultMessage: error.data.message,
				error: {},
				showFieldErrorsAsToast: true,
			})
		}
	}, [error])

	useEffect(() => {
		const triggerGetReservations = async () => {
			await getReservations({
				limit: rowsPerPage,
				offset: page * rowsPerPage
			})
			setIsLoading(false)
		}
		triggerGetReservations()
	}, [rowsPerPage, page])

	const handleSearch = useCallback(async (values: FormValues) => {
		try {
			getReservations({
				...values,
				limit: rowsPerPage,
				offset: page * rowsPerPage,
			})
		} catch (error: any) {
			console.log({ error })
		}
	}, [rowsPerPage, page])

	const formik = useFormik({
		initialValues: {
			reservationId: '',
			surname: '',
		},
		validationSchema,
		onSubmit: handleSearch,
	})

	if (isLoading) return <PageLoader />

	return (
		<Box sx={{ padding: 3 }}>
			<Typography variant='h4' sx={{ mb: 3 }}>Search Opera Reservation</Typography>
			<form noValidate onSubmit={formik.handleSubmit}>
				<Box sx={{ display: 'flex', width: '750px', justifyContent: 'space-between', alignItems: 'center' }}>
					<TextField
						error={Boolean(formik.touched.reservationId && formik.errors.reservationId)}
						helperText={formik.touched.reservationId && formik.errors.reservationId}
						sx={{ width: 300, margin: 0 }}
						label="Reservation Number"
						margin="normal"
						variant="outlined"
						name="reservationId"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						value={formik.values.reservationId}
						InputProps={{ sx: { borderRadius: '12px', backgroundColor: 'white' } }}
					/>
					<TextField
						error={Boolean(formik.touched.surname && formik.errors.surname)}
						helperText={formik.touched.surname && formik.errors.surname}
						sx={{ width: 300, margin: 0 }}
						label="Last Name"
						margin="normal"
						variant="outlined"
						name="surname"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						value={formik.values.surname}
						InputProps={{ sx: { borderRadius: '12px', backgroundColor: 'white' } }}
					/>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						sx={{
							height: '52px',
							width: '100px',
							my: 2,
							p: 2,
							textTransform: 'none',
							borderRadius: 3
						}}
					>
						Search
					</Button>
				</Box>
			</form>
			<Paper sx={{ mt: 4, borderRadius: '12px', overflow: 'hidden' }}>
				<Typography
					sx={{ pl: 3, paddingY: 2 }}
					variant='h5'
				>
					Reservations
				</Typography>
				{
					isFetching
						? <PageLoader />
						: <ReservationTable
							data={data?.reservationsData?.hotelReservations}
							pagination={data?.reservationsData?.pagination}
							rowsPerPage={rowsPerPage}
							page={page}
							setPage={setPage}
							setRowsPerPage={setRowsPerPage}
						/>
				}
			</Paper>
		</Box>
	);
};
