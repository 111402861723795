export function getValueFromNestedObject(obj: Record<string, any>, keys: string[]): any | undefined {
    let value = obj;
    for (const key of keys) {
        if (value && typeof value === 'object' && key in value) {
            value = value[key];
        } else {
            return undefined;
        }
    }
    return value;
}
