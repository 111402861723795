import React, { FC } from 'react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

import { Layout } from '../components/Layout';

const NoOrganizationPage: FC = () => {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Helmet>
                <title>JunoFront | Error: No Organization</title>
            </Helmet>
            <Layout>
                <Box
                    component="main"
                    sx={{
                        alignItems: 'center',
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexGrow: 1,
                        py: { xs: '20px', md: '40px', lg: '80px' },
                        height: '100%'
                    }}
                >
                    <Container maxWidth="lg">
                        <Box
                            component={"img"}
                            src="./contactus.webp"
                            width={300}
                            height={300}
                            sx={{ margin: 'auto' }}
                        />
                        <Typography
                            align="center"
                            variant='subtitle1'
                        >
                            Contact administrator
                        </Typography>
                    </Container>
                </Box>
            </Layout>
        </>
    );
};

export default React.memo(NoOrganizationPage);
