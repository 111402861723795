import React, { useState } from 'react';
import { AppBar, Box, Toolbar, Typography, styled } from '@mui/material';

import { Hamburger } from './Hamburger';
import { AppTitle } from './AppTitle';
import { More, UserAccount } from '../Actions';
import { DefaultMenu, MobileMenu } from './Menu';
import useSession from '../../hooks/useSession';
import { navClosedMixin, navOpenedMixin } from '../../styles/mixins';

interface HeaderProps {
	open: boolean;
	toggleNavigation: () => void;
}

interface HamburgerWrapperProps {
	open: boolean;
}

const HamburgerWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<HamburgerWrapperProps>(({ theme, open }) => ({
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...navOpenedMixin(theme),
		'& .MuiDrawer-paper': navOpenedMixin(theme),
	}),
	...(!open && {
		...navClosedMixin(theme),
		'& .MuiDrawer-paper': navClosedMixin(theme),
	}),
	height: '100%',
}));

export const Header = ({ open, toggleNavigation }: HeaderProps) => {
	const { user } = useSession()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setMobileMoreAnchorEl(event.currentTarget);

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	return (
		<>
			<AppBar
				position='fixed'
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					backgroundColor: 'white',
					boxShadow: 'none',
					height: 64,
				}}>
				<Toolbar disableGutters variant='dense'>
					<HamburgerWrapper open={open}>
						<Hamburger toggleNavigation={toggleNavigation} />
					</HamburgerWrapper>
					<AppTitle />
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center', color: "black" } }}>
						<Box sx={{ paddingLeft: 10, paddingRight: 2 }}>
							<Typography
								variant='subtitle1'
								noWrap
							>
								{user?.nickname}
							</Typography>
						</Box>
						<UserAccount onClick={handleProfileMenuOpen} />
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<More onClick={handleMobileMenuOpen} />
					</Box>
				</Toolbar>
			</AppBar>
			<MobileMenu
				isMenuOpen={Boolean(mobileMoreAnchorEl)}
				handleMenuOpen={handleMobileMenuOpen}
				handleMenuClose={handleMobileMenuClose}
				anchorEl={mobileMoreAnchorEl}
			/>
			<DefaultMenu isMenuOpen={Boolean(anchorEl)} handleMenuClose={handleMenuClose} anchorEl={anchorEl} />
		</>
	);
};
